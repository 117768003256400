<template>
  <div class="container" style="margin-top:70px;">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">Update Family Settings</h5>
    </div>
    <hr>

    <form @submit.prevent="handleFormSubmission">
        <div class="form-group">
          <label for="familyName" class="required">Family Name</label>
          <input name="familyname" type="text" class="form-control" v-model="familyName"  @change="validateFamilyName()"/>
          <ul class="error-feedback" v-if="familyNameErrors.length > 0" v-html="familyNameErrors"></ul>
        </div>



      <div class="form-group">
        <label for="country">Country</label>
        <select class="form-control" id="country" v-model="country" @change="getRegions()">
          <option value="">- Select One -</option>
          <option v-for="(country,idx) in countryRegionData" :key="idx" :value="country.countryName">{{country.countryName}}</option>
        </select>
        <ul class="error-feedback" v-if="countryErrors.length > 0" v-html="countryErrors"></ul>
      </div>

      <div class="form-group">
        <label for="region">State/Province/Region</label>
        <select class="form-control" id="region" v-model="region" @change="validateRegion()">
          <option value="">- Select One -</option>
          <option v-for="(region,idx) in regions" :key="idx" :value="region.name">{{region.name}}</option>
        </select>
        <ul class="error-feedback" v-if="regionErrors.length > 0" v-html="regionErrors"></ul>
      </div>

      <div class="form-group">
        <label for="currency">Currency</label>
        <select class="form-control" id="currency" v-model="currency" @change="validateCurrency()">
          <option value="">- Select One -</option>
          <option v-for="(currency,idx) in currencies" :key="idx" :value="currency.cc">{{currency.cc}} - {{currency.name}} - {{currency.symbol}}</option>
        </select>
        <ul class="error-feedback" v-if="currencyErrors.length > 0" v-html="currencyErrors"></ul>
      </div>




        <div class="row toggleRow">
          <div class=" col-auto me-auto" >Guarded by Dragon</div>
          <div class="col-auto" @click="toggleDragon">
            <font-awesome-icon v-if="guardedByDragon==='1'" class="toggleFa" :icon="['fas', 'toggle-on']" />
            <font-awesome-icon v-else class="toggleFa" :icon="['fas', 'toggle-off']" />
          </div>
        </div>

      <div class="row toggleRow">
        <div class=" col-auto me-auto" >Show Family Central Bank</div>
        <div class="col-auto" @click="toggleFamilyCb">
          <font-awesome-icon v-if="showFamilyCb==='1'" class="toggleFa" :icon="['fas', 'toggle-on']" />
          <font-awesome-icon v-else class="toggleFa" :icon="['fas', 'toggle-off']" />
        </div>
      </div>

      <div class="row toggleRow">
        <div class=" col-auto me-auto" >Play Click Sound</div>
        <div class="col-auto" @click="toggleClickSound">
          <font-awesome-icon v-if="clickSound==='1'" class="toggleFa" :icon="['fas', 'toggle-on']" />
          <font-awesome-icon v-else class="toggleFa" :icon="['fas', 'toggle-off']" />
        </div>
      </div>

      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading"><span v-show="loading" class="spinner-border spinner-border-sm"></span>Update</button>
        <div class="btn btn-danger btn-block" @click="showDeleteModal=true"><font-awesome-icon :icon="['fas', 'trash']"/> Delete Family</div>
      </div>
    </form>
  </div>


  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete your family? </h5>
      </div>

      <div class="modal-body">
        <div>
          All users, bank accounts and transactions will be permanently deleted and can not be recovered.
        </div>
        <div style="display:flex;justify-content: center;margin-top:30px;">
          <div class="btn btn-danger delConfirmBtn delYes clickable" @click="delFamily"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn btn-default delConfirmBtn delNo clickable" @click="showDeleteModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>

</template>

<script>

import countryRegionData from "@/services/countryregion.json";
import currencies from "@/services/currencies.json";
import {VueFinalModal } from 'vue-final-modal';

export default {
  name: 'FamilyProfile',
  components: {
    VueFinalModal
  },
  data(){


    return {
      userInfo:{},
      isAdmin:'0',
      successful: false,
      loading: false,
      message: "",
      familyName:'',
      familyNameErrors:'',
      hasValidationError: false,
      guardedByDragon:'0',
      showFamilyCb:'1',
      clickSound:'1',

      countryIdx:0,
      regionIdx:0,
      countryRegionData:countryRegionData,
      currencies:currencies,
      currency:'',
      currencyErrors:'',
      country:'',
      countryErrors:'',
      region:'',
      regionErrors:'',

      regions:[],

      formData:{},
      showDeleteModal:false,
    };
  },
  computed:{

  },
  methods:{
    getFamily: function(){
      this.axios
          .post(this.$hostApi,{task:'getFamily'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.familyName = response.data.NAME;
            this.country = response.data.COUNTRY;
            this.region = response.data.REGION;
            this.currency = response.data.CURRENCY_CODE;
            this.familyName = response.data.NAME;
            this.guardedByDragon = response.data.GUARDED_BY_DRAGON;
            this.showFamilyCb = response.data.SHOW_FAMILY_CB;
            this.clickSound = response.data.CLICK_SOUND;

            this.regions = this.countryRegionData.filter(c => c.countryName === this.country)[0].regions;


          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    delFamily: function(){
      this.axios
          .post(this.$hostApi,{task:'delFamily'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            if(response.data === 'ok'){
              this.logOut();
            }
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    logOut() {
      this.$logoutSound.play();

      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    getRegions: function(e,idx){
      this.validateCountry();
      this.region = '';
      this.regions = this.countryRegionData.filter(c => c.countryName === this.country)[0].regions;
    },



    handleFormSubmission(){
      this.$root.playClickSound();

      this.message = "";
      this.successful = false;
      this.loading = true;
      this.hasValidationError = false;

      this.validateAll();
      if(this.hasValidationError){
        this.loading = false;
        return;
      }

      this.formData = {
        'familyName':this.familyName.trim(),
        'guardedByDragon':this.guardedByDragon,
        'country':this.country,
        'region':this.region,
        'currency':this.currency,
        'showFamilyCb':this.showFamilyCb,
        'clickSound':this.clickSound
      };


      this.axios
          .post(this.$hostApi,{task:'updateFamily', data:this.formData},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            //this.message = response;
            this.successful = true;
            this.loading = false;

            console.log(response);
            //this.$root.refreshLocalMetaData().then(res => this.$router.go(-1));
            localStorage.setItem('familyInfo', JSON.stringify(response.data));
            this.$router.go(-1);

          })
          .catch(error => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          });

    },
    validateFamilyName(){
      this.familyNameErrors = this.$root.validateLength(this.familyName,2,50);
      if(this.familyNameErrors.length > 0){
        this.hasValidationError = true;
      }
    },
    validateCountry(){
      this.countryErrors = this.$root.validateLength(this.country,0,100);
      if(this.countryErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateRegion(){
      //console.log('region is '+this.region);
      this.regionErrors = this.$root.validateLength(this.region,0,100);
      if(this.regionErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateCurrency(){
      //console.log('region is '+this.region);
      this.currencyErrors = this.$root.validateLength(this.currency,0,100);
      if(this.currencyErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateAll(){
      this.validateFamilyName();
      this.validateCountry();
      this.validateRegion();
      this.validateCurrency();
    },

    toggleFamilyCb(){
      this.showFamilyCb = this.showFamilyCb === '1' ? '0' : '1';
    },

    toggleDragon(){
      this.guardedByDragon = this.guardedByDragon === '1' ? '0' : '1';
    },

    toggleClickSound(){
      this.clickSound = this.clickSound === '1' ? '0' : '1';
    },



  },
  created() {


  },
  mounted() {
    document.title = "Update Family Settings";
    this.getFamily();
  },

}
</script>

<style scoped>
.form-group{
  margin:15px 0;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.small, small {
  font-size: 80% !important;
  font-weight: 400;
}

.required:after {
  content: " *";
  color: red;
}

/*modal*/
.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.btn-default{
  background-color: #dcdbdb;
}

.btn{
  margin:0 5px;
}

.toggleFa{
  font-size:30px;
  margin-right:20px;
}

.toggleRow{
  padding:10px 0;
}


</style>
